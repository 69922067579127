const state = {
  show_resource_modal : false,
  resource_vendor_id : null,
  resource_list : {},
  resource_type:3,
  total_resource: 0,
  resource_start_from: 0
}
const actions = {
  fetchResources({commit,state}){
    axios.get("resource/list?vendor_id="+state.resource_vendor_id+"&start_from="+state.resource_start_from+"&resource_type_id="+state.resource_type)
    .then(function (response) {
      let res_data = {
        key : 'resource_list',
        data : response.data.data
      }
      commit('setResourceData',res_data)
    })
    .catch(function (e) {
      console.log(e);
    });
  }
}
const getters = {}
const mutations = {
  setResourceData(state,data){
    state[data.key] = data.data
  },
}
import axios from "@/helper/axios";
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}